// web公共api
import { Request } from '@/http/request'

export function initGeetest () {
    return Request.axiosInstance.post('/web/verify/generate')
}

export function sendSms (parameter: any) {
    return Request.axiosInstance.post('/web/sms/send', parameter)
}
export function sendCode (parameter: any) {
    return Request.axiosInstance.post('/system/auth/send-code', parameter)
}

export default { initGeetest, sendSms,sendCode }