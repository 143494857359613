// 注册用户登录
import { Request } from '@/http/request'

// 管理员登陆
export function login (parameter: any) {
  return Request.axiosInstance.post('/web/auth/login', parameter)
}
// 管理员登陆
export function loginByCode (parameter: any) {
  return Request.axiosInstance.post('/system/auth/login-by-code', parameter)
}

// 管理员注册
export function register (parameter: any) {
  return Request.axiosInstance.post('/web/auth/register', parameter)
}

// 注册用户重置密码 /auth/forget-password
export function forgetPassword (parameter: any) {
  return Request.axiosInstance.post('/web/auth/forget-password', parameter)
}

// 管理员登出
export function logout() {
  return Request.axiosInstance.post('/web/auth/logout')
}

export default { login, logout, register, forgetPassword,loginByCode }